import { createSlice } from '@reduxjs/toolkit'

const initialSummaryState = {
	listLoading: false,
	actionsLoading: false,
	entities: {},
	lastError: null,
}
export const callTypes = {
	list: 'list',
	action: 'action',
}

export const summarySlice = createSlice({
	name: 'summary',
	initialState: initialSummaryState,
	reducers: {
		catchError: (state, action) => {
			state.error = `${action.payload.error}`
			if (action.payload.callType === callTypes.list) {
				state.listLoading = false
			} else {
				state.actionsLoading = false
			}
		},
		startCall: (state, action) => {
			state.error = null
			if (action.payload.callType === callTypes.list) {
				state.listLoading = true
			} else {
				state.actionsLoading = true
			}
		},
		// getSummarys
		summarysFetched: (state, action) => {
			state.actionsLoading = false
			state.entities = action.payload
			state.error = null
		},
	},
})
