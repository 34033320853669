/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react'
import { useSelector } from 'react-redux'
// import { useLocation } from 'react-router'
// import { NavLink } from 'react-router-dom'
// import { checkIsActive } from '../../../../_helpers'

export function HeaderMenu({ layoutProps }) {
	// const location = useLocation()
	// const getMenuItemActive = (url) => {
	// 	return checkIsActive(location, url) ? 'menu-item-active' : ''
	// }
	const { user } = useSelector((state) => state.auth)

	return (
		<div
			id='kt_header_menu'
			className={`header-menu header-menu-mobile ${layoutProps.ktMenuClasses}`}
			{...layoutProps.headerMenuAttributes}
		>
			<h2 className='page-heading d-flex text-dark fw-bold fs-3 my-0 flex-column justify-content-center'>
				{user.current_business}
			</h2>
		</div>
	)
}
