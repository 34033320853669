import { createSlice } from '@reduxjs/toolkit'

const initialSettingsState = {
	listLoading: false,
	actionsLoading: false,
	totalCount: 0,
	entities: undefined,
	settingForEdit: undefined,
	lastError: null,
}
export const callTypes = {
	list: 'list',
	action: 'action',
}

export const settingsSlice = createSlice({
	name: 'settings',
	initialState: initialSettingsState,
	reducers: {
		catchError: (state, action) => {
			state.error = `${action.payload.error}`
			if (action.payload.callType === callTypes.list) {
				state.listLoading = false
			} else {
				state.actionsLoading = false
			}
		},
		startCall: (state, action) => {
			state.error = null
			if (action.payload.callType === callTypes.list) {
				state.listLoading = true
			} else {
				state.actionsLoading = true
			}
		},
		// getSettingById
		settingFetched: (state, action) => {
			state.actionsLoading = false
			state.settingForEdit = action.payload.settingForEdit
			state.error = null
		},
		// findSettings
		settingsFetched: (state, action) => {
			state.listLoading = false
			state.error = null
			state.entities = action.payload
		},
		// createSetting
		settingCreated: (state, action) => {
			state.actionsLoading = false
			state.error = null
			state.entities.push(action.payload.data)
		},
		// updateSetting
		settingUpdated: (state, action) => {
			state.error = null
			state.actionsLoading = false
			state.entities = state.entities.map((entity) => {
				if (entity.id === action.payload.setting.id) {
					return action.payload.setting
				}
				return entity
			})
		},
		// deleteSetting
		settingDeleted: (state, action) => {
			state.error = null
			state.actionsLoading = false
			state.entities = state.entities.filter(
				(el) => el.id !== action.payload.id
			)
		},
		// deleteSettings
		settingsDeleted: (state, action) => {
			state.error = null
			state.actionsLoading = false
			state.entities = state.entities.filter(
				(el) => !action.payload.ids.includes(el.id)
			)
		},
		// settingsUpdateState
		settingsStatusUpdated: (state, action) => {
			state.actionsLoading = false
			state.error = null
			const { ids, status } = action.payload
			state.entities = state.entities.map((entity) => {
				if (ids.findIndex((id) => id === entity.id) > -1) {
					entity.status = status
				}
				return entity
			})
		},
	},
})
