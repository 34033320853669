import { all } from 'redux-saga/effects'
import { combineReducers } from 'redux'

import * as auth from '../app/modules/Auth/_redux/authRedux'
import { couponsSlice } from '../app/modules/Coupon/_redux/coupons/couponsSlice'
import { promotionCodesSlice } from '../app/modules/PromotionCode/_redux/promotionCodes/promotionCodesSlice'
import { summarySlice } from '../app/pages/Dashboard/_redux/summarySlice'
import { invoicesSlice } from '../app/modules/Invoice/_redux/invoices/invoicesSlice'
import { settingsSlice } from '../app/modules/Setting/_redux/settings/settingsSlice'
import { customersSlice } from '../app/modules/Customer/_redux/customers/customersSlice'

export const rootReducer = combineReducers({
	auth: auth.reducer,
	coupons: couponsSlice.reducer,
	promotionCodes: promotionCodesSlice.reducer,
	summary: summarySlice.reducer,
	invoices: invoicesSlice.reducer,
	customers: customersSlice.reducer,
	settings: settingsSlice.reducer,
})

export function* rootSaga() {
	yield all([auth.saga()])
}
