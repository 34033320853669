import React from 'react'
import { useLocation } from 'react-router'
import { NavLink } from 'react-router-dom'
import { checkIsActive } from '../../../../_helpers'
import { useSelector, shallowEqual } from 'react-redux'

export function AsideMenuList({ layoutProps }) {
	const { role_level } = useSelector(
		(state) => state.auth.user.role_details,
		shallowEqual
	)
	const location = useLocation()
	const getMenuItemActive = (url, hasSubmenu = false) => {
		return checkIsActive(location, url)
			? ` ${!hasSubmenu &&
					'menu-item-active'} menu-item-open menu-item-not-hightlighted`
			: ''
	}

	return (
		<>
			{/* begin::Menu Nav */}
			<ul className={`menu-nav ${layoutProps.ulClasses}`}>
				{/* Admin Settings */}
				{/* begin::section */}
				<li className='menu-section '>
					<h4 className='menu-text'>Application</h4>
					<i className='menu-icon flaticon-more-v2'></i>
				</li>
				{/* end:: section */}

				{/*begin::1 Level*/}
				<li className={`menu-item ${getMenuItemActive('/dashboard', false)}`}>
					<NavLink className='menu-link' to='/dashboard'>
						<span className='svg-icon menu-icon'>
							<span className='material-icons'>dashboard</span>
						</span>
						<span className='menu-text'>Dashboard</span>
					</NavLink>
				</li>
				{/*end::1 Level*/}
				{/*begin::1 Level*/}
				<li
					className={`menu-item ${getMenuItemActive(
						'/promotion_codes',
						false
					)}`}
				>
					<NavLink className='menu-link' to='/promotion_codes'>
						<span className='svg-icon menu-icon'>
							<span className='material-icons'>receipt</span>
						</span>
						<span className='menu-text'>Promotion Code</span>
					</NavLink>
				</li>
				{/*end::1 Level*/}
				{/*begin::1 Level*/}
				{role_level <= 4 && (
					<>
						<li className={`menu-item ${getMenuItemActive('/coupons', false)}`}>
							<NavLink className='menu-link' to='/coupons'>
								<span className='svg-icon menu-icon'>
									<span className='material-icons'>receipt</span>
								</span>
								<span className='menu-text'>Coupons</span>
							</NavLink>
						</li>
						<li
							className={`menu-item ${getMenuItemActive('/customers', false)}`}
						>
							<NavLink className='menu-link' to='/customers'>
								<span className='svg-icon menu-icon'>
									<span className='material-icons'>group</span>
								</span>
								<span className='menu-text'>Customers</span>
							</NavLink>
						</li>
					</>
				)}
				{/*end::1 Level*/}
				{/*begin::1 Level*/}
				<li className={`menu-item ${getMenuItemActive('/invoices', false)}`}>
					<NavLink className='menu-link' to='/invoices'>
						<span className='svg-icon menu-icon'>
							<span className='material-icons'>account_balance_wallet</span>
						</span>
						<span className='menu-text'>Invoice</span>
					</NavLink>
				</li>
				{/*end::1 Level*/}
				{/*begin::1 Level*/}
				{role_level <= 1 && (
					<li className={`menu-item ${getMenuItemActive('/settings', false)}`}>
						<NavLink className='menu-link' to='/settings'>
							<span className='svg-icon menu-icon'>
								<span className='material-icons'>tune</span>
							</span>
							<span className='menu-text'>Settings</span>
						</NavLink>
					</li>
				)}
				{/*end::1 Level*/}
			</ul>
			{/* end::Menu Nav */}
		</>
	)
}
