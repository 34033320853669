import { createSlice } from '@reduxjs/toolkit'

const initialPromotionCodesState = {
	listLoading: false,
	actionsLoading: false,
	totalCount: 0,
	usageTotalCount: 0,
	entities: [],
	usage: [],
	stripePromotionCodes: [],
	promotionCodeForEdit: undefined,
	lastError: null,
}
export const callTypes = {
	list: 'list',
	action: 'action',
}

export const promotionCodesSlice = createSlice({
	name: 'promotionCodes',
	initialState: initialPromotionCodesState,
	reducers: {
		catchError: (state, action) => {
			state.error = `${action.payload.error}`
			if (action.payload.callType === callTypes.list) {
				state.listLoading = false
			} else {
				state.actionsLoading = false
			}
		},
		startCall: (state, action) => {
			state.error = null
			if (action.payload.callType === callTypes.list) {
				state.listLoading = true
			} else {
				state.actionsLoading = true
			}
		},
		// getPromotionCodeById
		promotionCodeFetched: (state, action) => {
			state.actionsLoading = false
			state.promotionCodeForEdit = action.payload.promotionCodeForEdit
			state.error = null
		},
		// findPromotionCodes
		promotionCodesFetched: (state, action) => {
			const { totalCount, entities } = action.payload
			state.listLoading = false
			state.error = null
			state.entities = entities
			state.totalCount = totalCount
		},
		promotionCodeUsageFetched: (state, action) => {
			const { totalCount, entities } = action.payload
			state.listLoading = false
			state.error = null
			state.usage = entities
			state.usageTotalCount = totalCount
		},
		stripePromotionCodesFetched: (state, action) => {
			const data = action.payload
			state.listLoading = false
			state.error = null
			state.stripePromotionCodes = data
		},
		// createPromotionCode
		promotionCodeCreated: (state, action) => {
			state.actionsLoading = false
			state.error = null
			state.entities.push(action.payload.data)
		},
		// updatePromotionCode
		promotionCodeUpdated: (state, action) => {
			state.error = null
			state.actionsLoading = false
			state.entities = state.entities.map((entity) => {
				if (entity.id === action.payload.promotionCode.id) {
					return action.payload.promotionCode
				}
				return entity
			})
		},
		statusUpdated: (state, action) => {
			state.error = null
			state.actionsLoading = false
			state.entities = state.entities.map((entity) => {
				if (entity.id === action.payload.id) {
					entity.status = action.payload.status
				}
				return entity
			})
		},
		// deletePromotionCode
		promotionCodeDeleted: (state, action) => {
			state.error = null
			state.actionsLoading = false
			state.entities = state.entities.filter(
				(el) => el.id !== action.payload.id
			)
		},
		// deletePromotionCodes
		promotionCodesDeleted: (state, action) => {
			state.error = null
			state.actionsLoading = false
			state.entities = state.entities.filter(
				(el) => !action.payload.ids.includes(el.id)
			)
		},
		// promotionCodesUpdateState
		promotionCodesStatusUpdated: (state, action) => {
			state.actionsLoading = false
			state.error = null
			const { ids, status } = action.payload
			state.entities = state.entities.map((entity) => {
				if (ids.findIndex((id) => id === entity.id) > -1) {
					entity.status = status
				}
				return entity
			})
		},
	},
})
