import React, { Suspense, lazy } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'
import { LayoutSplashScreen, ContentRoute } from '../_metronic/layout'

const CouponsPage = lazy(() =>
	import('./modules/Coupon/pages/coupons/CouponsPage')
)
const CustomersPage = lazy(() =>
	import('./modules/Customer/pages/customers/CustomersPage')
)
const PromotionCodesPage = lazy(() =>
	import('./modules/PromotionCode/pages/promotionCodes/PromotionCodesPage')
)
const DashboardPage = lazy(() => import('./pages/Dashboard/Dashboard'))
const InvoicePage = lazy(() =>
	import('./modules/Invoice/pages/invoices/InvoicesPage')
)
const SettingsPage = lazy(() => import('./modules/Setting/SettingEditDialog'))

export default function BasePage() {
	const { role_level } = useSelector(
		(state) => state.auth.user.role_details,
		shallowEqual
	)
	return (
		<Suspense fallback={<LayoutSplashScreen />}>
			<Switch>
				<Redirect exact from='/' to='/dashboard' />
				<ContentRoute path='/dashboard' component={DashboardPage} />
				<ContentRoute path='/promotion_codes' component={PromotionCodesPage} />
				<Route path='/invoices' component={InvoicePage} />
				{role_level <= 4 ? (
					<>
						<ContentRoute path='/coupons' component={CouponsPage} />
						<ContentRoute path='/customers' component={CustomersPage} />
					</>
				) : (
					<Redirect from='/coupons' to='/promotion_codes' />
				)}
				{role_level <= 1 ? (
					<ContentRoute path='/settings' component={SettingsPage} />
				) : (
					<Redirect to='/dashboard' />
				)}
				{/* <Redirect to='/error' /> */}
			</Switch>
		</Suspense>
	)
}
